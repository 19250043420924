import React, { useState } from "react";
import "./home.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import mentorImage from "../assets/home/Frame 7.svg";
import banner1 from "../assets/home/Group 17.png";
import banner2 from "../assets/home/Group 16.png";
import plus from "../assets/home/Vector.png";
import client1 from "../assets/home/logos/1.jpg";
import client2 from "../assets/home/logos/2.jpg";
import client3 from "../assets/home/logos/3.jpg";
import client4 from "../assets/home/logos/4.jpg";
import client5 from "../assets/home/logos/5.jpg";
import client6 from "../assets/home/logos/6.jpg";
import client7 from "../assets/home/logos/7.jpg";
import client8 from "../assets/home/logos/8.jpg";
import  project1 from '../assets/home/logos/icaiogg.jpg';
import  project2 from '../assets/home/logos/images (1).jpeg';
import  project3 from '../assets/home/logos/images.jpeg';

import article1 from "../assets/home/Rectangle 30.png";
import article2 from "../assets/home/Rectangle 30.png";
import article3 from "../assets/home/Rectangle 30.png";
import article4 from "../assets/home/Rectangle 30.png";
import tallye from "../assets/home/Group 8.png";
import outline from "../assets/home/Group 21.svg";
import profile1 from "../assets/home/Group 10.png";
import profile2 from "../assets/home/Group 11.png";
import profile3 from "../assets/home/Group 12.png";
import profile4 from "../assets/home/Group 14.png";
import profile5 from "../assets/home/Group 14.png";

const Home = () => {
  const [hovered, setHovered] = useState(null);

  const [showMore, setShowMore] = useState(false);

  const handleReadMoreClick = () => {
    setShowMore(true);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplaySpeed: 2000,
        },
      },
    ],
  };
  const [currentProfile, setCurrentProfile] = useState(profile1); // default center profile
  const [currentReview, setCurrentReview] = useState(
    "The support staff has been incredibly helpful throughout my journey. They guided me during the enrollment process and ensured I had access to all necessary resources for success.."
  );

  // Review texts for each side profile
  const reviews = {
    profile1:
      "Enrolling in this program was one of my best decisions. The instructors are highly knowledgeable and offer personalized support, ensuring every student grasps the material effectively.",
    profile2:
      "I had an amazing experience with this training program! The curriculum was well-structured, and I gained practical skills that helped me secure a job in my desired field.",
    profile3:
      "The training provided here is exceptional! I appreciated the hands-on approach and real-world applications of concepts, which significantly enhanced my understanding and confidence in my abilities.",
    profile4:
      "This institution exceeded my expectations in every way. The welcoming community and abundant networking opportunities made my learning experience truly rewarding and beneficial for my career growth.",
    profile5:
      "I highly recommend this program! The comprehensive courses and dedicated faculty prepared me well for the job market, boosting my confidence and making me more employable.",
  };

  // Function to update the center profile image and review text
  const handleProfileClick = (profileKey, profileImage) => {
    setCurrentProfile(profileImage);
    setCurrentReview(reviews[profileKey]);
  };

  const faqs = [
    {
      question: "What courses do you offer at Arena Info Solutions?",
      answer:
        "We offer a variety of courses including Tally, GST, DCA, and more to help you enhance your skills and career prospects.",
    },
    {
      question: "What is the duration of the courses?",
      answer:
        "Course durations vary, typically ranging from a few weeks to several months, depending on the course content and structure.",
    },
    {
      question: "Are the courses certified?",
      answer:
        "Yes, all our courses provide certification upon successful completion, which can help you in your career advancement.",
    },
    {
      question: "How can I enroll in a course?",
      answer:
        "You can enroll in a course by visiting our website, selecting the desired course, and completing the registration form.",
    },
    {
      question: "Is there any support available during the course?",
      answer:
        "Absolutely! Our instructors and support staff are always available to assist you with any questions or challenges you may face during your training.",
    },
    // Additional FAQ entries...
  ];

  return (
    <>
      <section className="header">
        <div className="home-container">
          {/* Main Section */}
          <div className="main-section">
            <h1>
              Perfect Place for <br />
              <span className="highlight"> Your Education</span> <br />
              Next Level
            </h1>
            <p className="description">
              At Arena Info Solutions, we focus on delivering high-quality
              education to students, empowering them with the skills and
              knowledge they need to thrive. Our comprehensive training programs
              cater to various sectors, helping students gain practical
              expertise and prepare for the challenges of the modern workforce.
            </p>
            <div className="button-group">
              <button className="btn-primary">View Courses →</button>
              <button className="btn-secondary">Get in Touch →</button>
            </div>
          </div>

          {/* Mentor Section */}
          <div className="mentor-section">
            <div className="mentor">
              <img
                src={mentorImage}
                alt="Tailored Training"
                className="mentor-icon"
              />
              <h3>Tailored Training</h3>
              <p>
                We offer custom programs to meet the unique needs of different
                communities.
              </p>
            </div>

            <div className="mentor1">
              <img
                src={mentorImage}
                alt="Vocational Skills"
                className="mentor-icon"
              />
              <h3>Vocational Skills</h3>
              <p>
                Our training boosts employability and helps develop
                entrepreneurial skills.
              </p>
            </div>

            <div className="mentor2">
              <img
                src={mentorImage}
                alt="Holistic Development"
                className="mentor-icon"
              />
              <h3> Development</h3>
              <p>
                We focus on technical and soft skills for all-round professional
                growth.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="header2">
        <div className="data">
          {/* Left side image */}
<div className="left">
            <img src={banner1} alt="Educational Banner" className="banner" />
          </div>          

          {/* Right side text */}
          <div className="right">
            <h2>Total Enrolled Students</h2>
            <h1>We Enrolled 70,000+ Students from 2007 to 2024</h1>
            <p>
              Over the years, we have proudly enrolled more than 70,000
              students, achieving a remarkable enrollment rate of 43%. Our
              commitment to providing quality education has enabled us to reach
              this milestone from 2007 to 2024. We focus on creating a learning
              environment that empowers students with the necessary skills to
              thrive in their careers.
            </p>
            <p>
              <strong>Targets Achieved:</strong> Our placement data for the
              academic year 2023-2024 showcases our dedication to student
              success. Our alumni have gone on to secure roles in reputable
              organizations, further enhancing our institution's reputation.
            </p>
            <p>
              Our training initiatives have included various short-term
              programs, such as Recognition of Prior Learning (RPL), Chamb
              training, and Corporate Social Responsibility (CSR) projects in
              Massoone. We have also conducted specialized courses in Tally and
              GST, equipping our students with practical skills that are in high
              demand in the job market.
            </p>

            {/* Conditionally render the last paragraph */}
            {showMore && (
              <p>
                Looking ahead to the academic year 2024-2025, we have set
                ambitious targets. We aim to achieve approximately ₹10 crore in
                placements, which reflects our commitment to continuous
                improvement and excellence in education.
              </p>
            )}

            <button className="read-more" onClick={handleReadMoreClick}>
              Read more
            </button>
          </div>
        </div>
      </section>

      <section className="online">
        <div className="online-left">
          <h3>Govt. Training</h3>
          <h1>Projects We Are Running</h1>
          <div className="projects-list">
            <ul>
              <li>ICAI</li>
              <li>NSK</li>
              <li>Jawahar Navodaya Vidyalaya</li>
              <li>ITI's</li>
            </ul>
            <ul>
              <li>UCO RSETI</li>
              <li>HPU</li>
              <li>MSME</li>
              <li>Decathlon </li>
            </ul>
          </div>
        </div>

        <div className="online-right">
      <div className="ocourse-grid">
        <div className="ocourse-box">
          <img src={client1} alt="Client 1" />
        </div>
        <div className="ocourse-box">
          <img src={project1} alt="" />
        </div>
        <div className="ocourse-box">
          <img src={project2} alt="Client 1" />
        </div>
        <div className="ocourse-box">
          <img src={project3} alt="Client 1" />
        </div>
        <div className="ocourse-box">
          <img src={client2} alt="Client 1" />
        </div>
        <div className="ocourse-box">
          <img src={client5} alt="Client 1" />
        </div>
        <div className="ocourse-box">
          <img src={client6} alt="Client 1" />
        </div>
        <div className="ocourse-box">
          <img src={client8} alt="Client 1" />
        </div>
      </div>
    </div>

      </section>

      <section className="courses">
        <div className="courses-header">
          <h2>Browse Courses</h2>
          <h3>We offer the best career-oriented training</h3>
          <p>
            Our courses provide comprehensive knowledge and skills for your
            professional growth.
          </p>
        </div>

        <div className="course-grid">
          <div className="course-card">
            <div className="course-image">
              <img src={tallye} alt="Tally Course" />
            </div>
            <div className="course-content">
              <h4>BASIC OF COMPUTER (BOC)</h4>
              <p>
                Learn fundamental computer skills like file management, Office
                tools, and internet use.
              </p>
              <div className="course-meta">
                <span className="videos-count">🕒 15 videos</span>
                <span className="course-duration">📅 1 Month</span>
              </div>
            </div>
          </div>

          <div className="course-card">
            <div className="course-image">
              <img src={tallye} alt="Tally Course" />
            </div>
            <div className="course-content">
              <h4>DIPLOMA IN COMPUTER APPLICATION (DCA)</h4>
              <p>
                Covers various applications like office tools, databases, and
                basic programming concepts.
              </p>
              <div className="course-meta">
                <span className="videos-count">🕒 30 videos</span>
                <span className="course-duration">📅 6 Months</span>
              </div>
            </div>
          </div>

          <div className="course-card">
            <div className="course-image">
              <img src={tallye} alt="Tally Course" />
            </div>
            <div className="course-content">
              <h4>PGDCA</h4>
              <p>
                Enhance your technical expertise in software, hardware, and
                systems with PGDCA.
              </p>
              <div className="course-meta">
                <span className="videos-count">🕒 40 videos</span>
                <span className="course-duration">📅 1 Year</span>
              </div>
            </div>
          </div>

          <div className="course-card">
            <div className="course-image">
              <img src={tallye} alt="Tally Course" />
            </div>
            <div className="course-content">
              <h4>TALLY LEVEL 1</h4>
              <p>Introduction to Tally for basic accounting and bookkeeping.</p>
              <div className="course-meta">
                <span className="videos-count">🕒 20 videos</span>
                <span className="course-duration">📅 2 Months</span>
              </div>
            </div>
          </div>

          <div className="course-card">
            <div className="course-image">
              <img src={tallye} alt="Tally Course" />
            </div>
            <div className="course-content">
              <h4>TALLY LEVEL 2</h4>
              <p>
                Learn advanced Tally features like payroll management,
                budgeting, and reports.
              </p>
              <div className="course-meta">
                <span className="videos-count">🕒 25 videos</span>
                <span className="course-duration">📅 3 Months</span>
              </div>
            </div>
          </div>

          <div className="course-card">
            <div className="course-image">
              <img src={tallye} alt="Tally Course" />
            </div>
            <div className="course-content">
              <h4>TALLY LEVEL 3</h4>
              <p>
                Advanced inventory management, taxation, and financial reporting
                using Tally.
              </p>
              <div className="course-meta">
                <span className="videos-count">🕒 30 videos</span>
                <span className="course-duration">📅 4 Months</span>
              </div>
            </div>
          </div>

          <div className="course-card">
            <div className="course-image">
              <img src={tallye} alt="Tally Course" />
            </div>
            <div className="course-content">
              <h4>GST USING TALLY PRIME</h4>
              <p>Manage GST filing, returns, and reports using Tally Prime.</p>
              <div className="course-meta">
                <span className="videos-count">🕒 15 videos</span>
                <span className="course-duration">📅 1 Month</span>
              </div>
            </div>
          </div>

          <div className="course-card">
            <div className="course-image">
              <img src={tallye} alt="Tally Course" />
            </div>
            <div className="course-content">
              <h4>TALLY PROFESSIONAL</h4>
              <p>
                Become a Tally expert with in-depth knowledge of financial
                analysis and ERP systems.
              </p>
              <div className="course-meta">
                <span className="videos-count">🕒 35 videos</span>
                <span className="course-duration">📅 6 Months</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="header3">
        <div className="data">
          {/* Left side image */}
          <div className="left">
            <img src={banner2} alt="Educational Banner" className="banner" />
          </div>

          {/* Right side text */}
          <div className="right">
            <h2>Why Us</h2>
            <h1>
              Your Success, Our Priority in <br /> Education
            </h1>

            <p>
              We are proud to partner with some of the finest educational
              institutions to help students reach their goals. Our
              collaborations with renowned universities ensure you get access to
              quality education and opportunities. The institutions we tie up
              with provide a rich and diverse learning environment to foster
              growth and development. Whether you are pursuing higher education
              or specialized courses, we connect you with the best options to
              fit your needs. Our partnerships span across highly esteemed
              universities, committed to delivering excellence in education.
              COLLEGES WE TIE UP WITH: • HPU • Chandigarh University • Chitkara
              University These institutions are dedicated to providing you with
              a bright future.
            </p>
          </div>
        </div>
      </section>

      <section className="video">
        <div className="video-wrapper">
          <iframe
            width="84%"
            height="500" // Adjust this height as needed
            src="https://www.youtube.com/embed/ja69wYeqzTI?si=bKzouptbUeG8CmdM"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>

        <div className="online-mentor-section">
          <div className="omentor">
            <img
              src={mentorImage}
              alt="Expert Guidance"
              className="mentor-icon"
            />
            <h3>Expert Guidance</h3>
            <p>
              Our mentors are experienced professionals who provide practical
              advice to help you reach your goals.
            </p>
          </div>

          <div className="omentor">
            <img
              src={mentorImage}
              alt="Personalized Support"
              className="mentor-icon"
            />
            <h3>Personalized Support</h3>
            <p>
              Receive one-on-one guidance tailored to your strengths, helping
              you succeed in your academic journey.
            </p>
          </div>

          <div className="omentor">
            <img
              src={mentorImage}
              alt="Wide Network of Colleges"
              className="mentor-icon"
            />
            <h3>Wide Network of Colleges</h3>
            <p>
              Our partnerships with top universities open doors to numerous
              academic opportunities.
            </p>
          </div>
        </div>
      </section>

      <section className="feedback">
        <h1>Feedback</h1>

        <div className="reviews">
          <div className="review-text">
            {/* Outline image */}
            <img src={outline} alt="Review Outline" className="outline-image" />
            <div className="review-quote">
              {/* The review text */}
              <p>{currentReview}</p>
            </div>
          </div>

          <div className="profile-container">
            {/* Profile in the center */}
            <div className="center-profile">
              <img
                src={currentProfile}
                alt="Current Profile"
                className="center-profile-img"
              />
            </div>
            {/* Profiles around the center */}
            <div className="side-profiles">
              <img
                src={profile2}
                alt="Profile 2"
                className="side-profile-img"
                onClick={() => handleProfileClick("profile2", profile2)}
              />
              <img
                src={profile3}
                alt="Profile 3"
                className="side-profile-img"
                onClick={() => handleProfileClick("profile3", profile3)}
              />
              <img
                src={profile4}
                alt="Profile 4"
                className="side-profile-img"
                onClick={() => handleProfileClick("profile4", profile4)}
              />
              <img
                src={profile5}
                alt="Profile 5"
                className="side-profile-img"
                onClick={() => handleProfileClick("profile5", profile5)}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="articles">
        <h2>Articles</h2>
        <h3>Explore Our Latest Articles</h3>
        <p>
          Stay informed with the latest insights and trends in education, career
          development, and technology. Our articles cover a wide range of topics
          to help you succeed in your academic and professional journey.
        </p>
        <div className="articles-grid">
          <div className="article-card">
            <img src={article1} alt="Article 1" />
            <div className="article-content">
              <span className="tag">Education</span>
              <h4>How to Choose the Right College for Your Future</h4>
              <p>
                Selecting the right college can shape your career and personal
                growth. Learn how to evaluate institutions based on your goals
                and preferences.
              </p>
            </div>
          </div>
          <div className="article-card">
            <img src={article2} alt="Article 2" />
            <div className="article-content">
              <span className="tag">Career</span>
              <h4>Top Skills You Need to Succeed in Today's Job Market</h4>
              <p>
                Discover the key skills employers are looking for in the modern
                workforce, and how to develop them to boost your employability.
              </p>
            </div>
          </div>
          <div className="article-card">
            <img src={article3} alt="Article 3" />
            <div className="article-content">
              <span className="tag">Tally</span>
              <h4>The Importance of Tally in Modern Accounting</h4>
              <p>
                Tally has become a vital tool in managing financial operations
                for businesses of all sizes. Explore how learning Tally can
                boost your accounting skills and help you manage financial
                records with ease.
              </p>
            </div>
          </div>

          <div className="article-card">
            <img src={article4} alt="Article 4" />
            <div className="article-content">
              <span className="tag">Productivity</span>
              <h4>Time Management Tips for Students</h4>
              <p>
                Managing your time effectively is crucial to academic success.
                Get practical tips on how to stay organized and make the most of
                your study sessions.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="clients-slider">
        <Slider {...settings}>
          <div>
            <img src={client1} alt="Client 1" />
          </div>
          <div>
            <img src={client2} alt="Client 2" />
          </div>
          <div>
            <img src={client3} alt="Client 3" />
          </div>
          <div>
            <img src={client4} alt="Client 4" />
          </div>
          <div>
            <img src={client5} alt="Client 5" />
          </div>
          <div>
            <img src={client6} alt="Client 6" />
          </div>
          <div>
            <img src={client7} alt="Client 7" />
          </div>
          <div>
            <img src={client8} alt="Client 8" />
          </div>
        </Slider>
      </section>

      {/* FAQ Section */}
      <section className="faq">
        <h2>FAQs </h2>
        <h3>Frequently Asked Questions</h3>
        <p>Below are some commonly asked questions along with their answers:</p>
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="faq-item"
              onMouseEnter={() => setHovered(index)}
              onMouseLeave={() => setHovered(null)}
            >
              <div className="faq-question">
                <img src={plus} alt="plus" className="faq-plus" />
                <span>{faq.question}</span>
              </div>
              {hovered === index && (
                <div className="faq-answer">{faq.answer}</div>
              )}
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Home;
