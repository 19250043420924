import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import logo from '../assets/arena.svg'; // Replace with your actual logo path
import arrow from '../assets/keyboard_arrow_down.png'; // Replace with your arrow icon path
import './navbar.css';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={logo} alt="Arena Logo" />
      </div>
      <ul className="navbar-links">
        {/* Use Link for navigation */}
        <li><Link to="/">Home</Link></li>
        <li><Link to="/404">Courses <img src={arrow} alt="arrow" className="arrow-icon" /></Link></li>
        <li><Link to="/404">About</Link></li>
        <li><Link to="/404">Blog <img src={arrow} alt="arrow" className="arrow-icon" /></Link></li>
        <li><Link to="/404">Pages <img src={arrow} alt="arrow" className="arrow-icon" /></Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;
