import React from 'react';

const NotFound = () => {
  // Inline styles for the container, heading, paragraph, and link
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '80vh',
      textAlign: 'center',
      backgroundColor: '#f7f7f7',
      color: '#333',
    },
    heading: {
      fontSize: '3rem',
      fontWeight: 'bold',
      marginBottom: '20px',
      color: '#ff6b6b',
    },
    paragraph: {
      fontSize: '1.5rem',
      color: '#555',
    },
    link: {
      marginTop: '20px',
      fontSize: '1.2rem',
      color: '#ff6b6b',
      textDecoration: 'none',
      border: '2px solid #ff6b6b',
      padding: '10px 20px',
      borderRadius: '5px',
      transition: 'all 0.3s ease',
    },
    linkHover: {
      backgroundColor: '#ff6b6b',
      color: '#fff',
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>404 - Page Not Found</h1>
      <p style={styles.paragraph}>The page you are looking for does not exist.</p>
      <a
        href="/"
        style={styles.link}
        onMouseOver={(e) => (e.currentTarget.style = `background-color: ${styles.linkHover.backgroundColor}; color: ${styles.linkHover.color}`)}
        onMouseOut={(e) => (e.currentTarget.style = `color: ${styles.link.color}; border: 2px solid ${styles.link.color}; padding: 10px 20px; border-radius: 5px; text-decoration: none;`)}
      >
        Go Back to Home
      </a>
    </div>
  );
};

export default NotFound;
