import React from 'react';
import logo from '../assets/arena.svg'; // Use the correct path to your logo
import './footer.css'; // Include the CSS for this component

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        
        {/* Left Section with Logo and Text */}
        <div className="footer-left">
          <img src={logo} alt="Arena Info Solutions Logo" className="footer-logo" />
          <p>
            Arena Info Solutions is committed to providing quality education, equipping students with the skills to thrive in today's competitive world. 
            Our tailored courses and expert mentors ensure practical learning and professional growth.
          </p>
        </div>

        {/* Middle Section with Links */}
        <div className="footer-middle">
          <div className="footer-column">
            <h4>Quick Links</h4>
            <ul>
              <li><a href="#about">About Us</a></li>
              <li><a href="#courses">Courses</a></li>
              <li><a href="#blog">Blog</a></li>
              <li><a href="#contact">Contact Us</a></li>
              <li><a href="#pricing">Pricing</a></li>
            </ul>
          </div>

          <div className="footer-column">
            <h4>Resources</h4>
            <ul>
              <li><a href="#faq">FAQ</a></li>
              <li><a href="#support">Support</a></li>
              <li><a href="#profile">Student Portal</a></li>
              <li><a href="#checkout">Checkout</a></li>
            </ul>
          </div>
        </div>
        
        {/* Right Section with Contacts */}
        <div className="footer-right">
          <h4>Get In Touch</h4>
          <p>
            Have any questions? We'd love to hear from you. Reach out to us and we’ll get back to you shortly.
          </p>
          <div className="footer-contact">
            <input type="email" placeholder="Your email" className="footer-input" />
            <button className="footer-button">Subscribe</button>
          </div>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
